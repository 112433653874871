import { Route, Routes } from "react-router-dom";

import Home from "../features/home/routes/Home";
import Cookies from "../features/cookies/routes/Cookies";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/cookies" element={<Cookies />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
};
