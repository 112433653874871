import { AppProvider } from "./providers/app";
import { AppRoutes } from "./routes";
import "./App.css";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  return (
    <AppProvider>
      <ScrollToTop />
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
