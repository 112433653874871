import styled from "styled-components";

import image1 from "../../../assets/images/calle13-default.png";
import image2 from "../../../assets/images/syfy-default.png";
import image3 from "../../../assets/images/dreamworks-default.png";

type Props = {};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  width: 125px;
  cursor: pointer;
`;

const LogoImage = styled.img`
  width: 100%;
`;

const Logos = (props: Props) => {
  return (
    <Container>
      <LogoContainer>
        <a
          href="https://www.calle13.es/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoImage src={image1} alt="Calle 13" />
        </a>
      </LogoContainer>
      <LogoContainer>
        <a
          href="https://www.syfy.es/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoImage src={image2} alt="Syfy" />
        </a>
      </LogoContainer>
      <LogoContainer>
        <a
          href="https://www.dreamworks.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <LogoImage src={image3} alt="Dreamworks" />
        </a>
      </LogoContainer>
    </Container>
  );
};

export default Logos;
