import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

type Props = {
  children: React.ReactElement;
};

const ScrollToTop = ({ children }: Props) => {
  const location = useLocation();
  const [loc, setLoc] = useState(location);

  useEffect(() => {
    if (location !== loc) {
      window.scrollTo(0, 0);
      setLoc(location);
    }
  }, [location]);

  return children;
};

ScrollToTop.defaultProps = {
  children: null,
};

export default ScrollToTop;
