import { createElement, useEffect } from "react";
import styled from "styled-components";

import { Footer } from "../../../components/Footer";
import { Head } from "../../../components/Head/Head";

const ContentPageLayout = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const ContentRowsLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: calc(100vh - 134px);
`;

const Heading = styled.div`
  color: #333;
  margin-bottom: 20px;

  h1 {
    font-size: 24px;
    font-weight: 700;
  }
`;

const Cookies = () => {
  return (
    <>
      <Head
        title={"Universal Plus | Cookies Page"}
        description={"Universal Plus Spain Cookies page"}
        route={""}
      />
      <ContentPageLayout>
        <ContentRowsLayout>
          <Heading>
            <h1>Cookies</h1>
          </Heading>
          {/* OneTrust Cookies List start */}
          <div style={{ padding: "0 8px" }} id="ot-sdk-cookie-policy"></div>
          {/* OneTrust Cookies List end  */}
        </ContentRowsLayout>
      </ContentPageLayout>
      <Footer />
    </>
  );
};

export default Cookies;
