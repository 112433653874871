import * as React from "react";
import { Helmet } from "react-helmet-async";

type HeadProps = {
  title: string;
  description: string;
  route: string;
};

export const Head = ({ title, description, route }: HeadProps) => {
  return (
    <Helmet title={title ? `${title}` : undefined}>
      <meta name="description" content={description} />
      <link rel="canonical" href={`${route}`} />
    </Helmet>
  );
};
