import { MouseEvent } from "react";
import styled from "styled-components";

import Logos from "../../features/home/components/Logos";

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    color: #ffffff90;
  }
`;

const Title = styled.h1`
  font-size: 26px;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Paragraph = styled.p`
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Container = styled.div`
  text-align: center;
`;

const Backdrop = styled.div`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: transparent;
`;

interface ModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

const ModalContainer = styled.div`
  position: fixed;
  width: 80vw;
  height: 90%;
  z-index: 1040;
  top: 50%;
  left: 50%;
  color: #fff;
  text-align: center;
  transform: translate(-50%, -50%);
  border: none;
  border-radius: 10px;
  background-color: #000;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  padding: 20px;
  opacity: 0.85;
`;

const Button = styled.button`
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  color: white;
  margin: 0px;
  padding: 8px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #ffffff90;
  }

  @media (max-width: 400px) {
    display: none;
`;

const Modal: React.FC<ModalProps> = ({ show, setShow }: any) => {
  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    setShow(false);
  };

  return (
    <>
      {show && (
        <Backdrop>
          <ModalContainer>
            <CloseButton onClick={handleClose}>&times;</CloseButton>
            <Container>
              <Title>
                {`DISFRUTA DE LOS MEJORES CONTENIDOS DE CALLE 13, SYFY Y
                  DREAMWORKS EN UNIVERSAL+`}
              </Title>
              <Paragraph>
                NBCUniversal International Networks aumenta su oferta de
                contenido bajo demanda en España con Universal+, que reúne las
                mejores series de Calle 13 y SYFY en un mismo lugar. Universal+
                es un servicio híbrido lineal y no lineal con una enorme
                selección de títulos bajo demanda y estrenos de nuevas series.
                Además, DreamWorks, el canal dedicado exclusivamente a
                contenidos para los más pequeños de la familia, está disponible
                en emisión lineal, así como también bajo demanda en Universal+.
              </Paragraph>
              <Paragraph>
                Los clientes podrán disfrutar del enorme catálogo de contenidos
                de los canales, desde thrillers hasta ciencia ficción pasando
                por dramas policiales, Universal+ ofrece una nueva forma de
                acceder a una apasionante selección de contenidos.
              </Paragraph>
            </Container>
            <Logos />
            <Button onClick={() => setShow(false)}>Menos informacion</Button>
          </ModalContainer>
        </Backdrop>
      )}
    </>
  );
};

export default Modal;
