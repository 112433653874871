import { MouseEvent, useState } from "react";
import styled from "styled-components";

type Props = {};

import image1 from "../../../assets/images/landscape.jpg";
import image2 from "../../../assets/images/portrait.jpg";
import Modal from "../../../components/Modal/Modal";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const StyledImage = styled.img`
  max-height: 100vh;
  max-width: 100vw;
  height: auto;
  width: auto;
  object-fit: cover;
  object-position: center;
`;

const Button = styled.button`
  position: absolute;
  top: 90%;
  left: 50%;
  margin: 0;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  color: white;
  padding: 8px 22px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #ffffff90;
  }

  @media (max-width: 400px) {
    top: 75%;
  }
`;

export const Image = (props: Props) => {
  const [show, setShow] = useState(false);

  const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
    setShow(true);
  };

  return (
    <Container>
      <ImageContainer>
        <picture>
          <source media="(max-width: 320px)" srcSet={image2} />
          <source media="(max-width: 640px)" srcSet={image2} />
          <source media="(max-width: 960px)" srcSet={image1} />
          <source media="(min-width: 1200px)" srcSet={image1} />
          <StyledImage src={image1} alt={"Universal Plus Spain"} />
        </picture>
      </ImageContainer>

      <Button onClick={handleClose}>Mas Information</Button>
      <Modal show={show} setShow={setShow} />
    </Container>
  );
};
