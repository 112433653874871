import * as React from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";

import logoImage from "../../assets/images/logo-small.png";

const FooterContainer = styled.footer`
  background-color: #000;
  color: #ecf0f1;
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  font-size: 14px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const StyledExternalLink = styled.a`
  color: #ecf0f1;
  text-decoration: none;
  text-align: left;
  margin: 0px 0px 8px;
  &:hover {
    text-decoration: underline;
  }
`;

const LogoButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;

  img {
    width: 220px;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
`;

const Copyright = styled.p`
  margin: 0;

  @media (max-width: 768px) {
    margin-top: 20px;
    text-align: center;
  }
`;

export const Footer = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  return (
    <FooterContainer>
      <LinksContainer>
        <h2>Site Links</h2>

        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button>

        {/* <button
          style={{
            background: "none",
            border: "none",
            color: "#fff",
            textAlign: "left",
            cursor: "pointer",
            padding: "0px",
            margin: "0px 0px 8px",
          }}
          onClick={() => {
            navigate("/cookies");
            window.location.reload();
          }}
        >
          Cookies
        </button> */}

        <StyledExternalLink
          href="https://nbcuniversal.com/privacy-policy/aviso-sobre-cookies-0#accordionheader2"
          target="_blank"
          rel="noreferrer"
        >
          Opciones de anuncios
        </StyledExternalLink>

        <StyledExternalLink
          href="https://www.nbcuniversal.com/privacy/spanish"
          target="_blank"
          rel="noreferrer"
        >
          Política de privacidad
        </StyledExternalLink>

        <StyledExternalLink
          href="https://www.nbcuniversal.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          Términos y condiciones
        </StyledExternalLink>
      </LinksContainer>
      <LogoButton onClick={() => navigate("/")}>
        <img src={logoImage} alt="Company Logo" />
      </LogoButton>
      <Copyright>© {currentYear} NBCUniversal. All rights reserved.</Copyright>
    </FooterContainer>
  );
};
