import { useEffect } from "react";

import { Footer } from "../../../components/Footer";
import { Head } from "../../../components/Head/Head";
import { Image } from "../components/Image";

type Props = {};

const Home = (props: Props) => {
  return (
    <>
      <Head
        title={"Universal Plus | Home Page"}
        description={"Universal Plus Spain home page"}
        route={""}
      />
      <div>
        <Image />
      </div>
      <Footer />
    </>
  );
};

export default Home;
